import {createContext, useState} from 'react';

const FavsContext = createContext({
    favs: [],
    totalFavs : 0,
    addFav: (favMeetup) =>{},
    removeFav: (meetupId) =>{},
    itemIsFav: (meetupId) => {}
});


export function FavContextProvider(props){
    const [userFavs, setUserFavs] = useState([])

    function addFavHandler(favMeetup){
        setUserFavs((prevUserFavs) => {
            return prevUserFavs.concat(favMeetup);
        });
    }

    function removeFavoriteHandler(meetupId){
        setUserFavs(prevUserFavs => {
            return prevUserFavs.filter(meetup => meetup.id !== meetupId);
        });
    }

    function itemIsFavoriteHandler(meetupId){
        return userFavs.some(meetup=> meetup.id === meetupId)
    }

    const context = {
        favs: userFavs,
        totalFavs: userFavs.length,

        addFav: addFavHandler,
        removeFav: removeFavoriteHandler,
        itemIsFav: itemIsFavoriteHandler
    };

    
    return (
        <FavsContext.Provider value={context}>
            {props.children}
        </FavsContext.Provider>
    );
}

export default FavsContext;