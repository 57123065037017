import { Link } from 'react-router-dom';
import classes from './MainNavigation.module.css';

import FavsContext from '../../store/fav-context';
import { useContext } from 'react';

function MainNavigation() {
  const favsCtxt = useContext(FavsContext);

  return (
    <header className={classes.header}>
      <div className={classes.logo}>React Meetups</div>
      <nav>
        <ul>
          <li>
            <Link to='/'>All Meetups</Link>
          </li>
          <li>
            <Link to='/new'>Add New Meetup</Link>
          </li>
          <li>
            <Link to='/fav'>My Favorites<span className={classes.badge}>{favsCtxt.totalFavs}</span></Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default MainNavigation;