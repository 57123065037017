
import {Routes,Route} from 'react-router-dom';

import AllMeetupsPage from './pages/AllMeetups';
import NewMeetupPage from './pages/NewMeetup';
import FavoritesPage from './pages/Favorites';

import Layout from './components/layout/Layout';

//import {BrowserRouter} from 'react-router-dom';

function App() {
  return (
    <div>
      <Layout>
        <Routes>
          <Route path='/' element={<AllMeetupsPage/>}></Route>
          <Route path='/new' element={<NewMeetupPage/>}></Route>              
          <Route path='/fav' element={<FavoritesPage/>}></Route>
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
