import {React, useContext} from "react";
import FavsContext from "../store/fav-context";
import MeetupList from "../components/meetups/MeetupList";


function FavoritesPage(){
    const favsCtx = useContext(FavsContext);

    let content;

    if (favsCtx.totalFavs === 0){
        content = <p>No favs yet - add some</p>
    }else{
        content = <MeetupList meetups={favsCtx.favs}></MeetupList>
    }

    return (
        <section>
            <h1>My Favs</h1>
            {content}
        </section>
    );
}


export default FavoritesPage;