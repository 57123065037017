import { useContext } from 'react';
import Card from '../ui/Card.js';

import classes from './MeetupItem.module.css';
import FavsContext from '../../store/fav-context';

function MeetupItem(props){

    const favsCtx = useContext(FavsContext);
    const itemIsFav = favsCtx.itemIsFav(props.id);

    function toggleFavStatusHandler(){
        if (itemIsFav){
            favsCtx.removeFav(props.id);
        }else{
            favsCtx.addFav({
                id: props.id,
                title: props.title,
                description: props.description,
                image: props.image,
                address: props.address
            });
        }
    }

    return(
        <Card>
            <li className = {classes.item}>
                <div className = {classes.image}>
                    <img src={props.image} alt ={props.title}/>
                </div>
                <div className = {classes.content}>
                    <h3>{props.title}</h3>
                    <address>{props.address}</address>
                    <p>{props.description}</p>
                </div>
                <div className={classes.actions}>
                    <button onClick={toggleFavStatusHandler}>{itemIsFav ? 'Remove Fav' : 'Add to Fav'}</button>
                </div>
            </li>
        </Card>
    );
}

export default MeetupItem;